/**
 * Created by osirvent on 24/04/2017.
 */
angular
    .module('annexaApp')
    .component('annexaDocumentBasicInfoBox', {
        templateUrl: './components/doc/annexa-document-basic-info-box/annexa-document-basic-info-box.html',
        require: {
            documentComponent: '^^annexaDocument'
        },
        controller: ['$rootScope', 'Language', '$filter', 'DccumentsFactory', 'GlobalDataFactory', 'CommonService', 'DialogsFactory', 'RestService', 'HelperService', function ($rootScope, Language, $filter, DccumentsFactory, GlobalDataFactory, CommonService, DialogsFactory, RestService, HelperService) {
            var vm = this;

            vm.profiles = [];
            vm.profilesBckup = [];
            vm.languageColumn = Language.getActiveColumn();
            vm.archiveClassifications = GlobalDataFactory.archiveClassifications;
            vm.profilesUser = DccumentsFactory.profilesUser;
            vm.allProfiles = GlobalDataFactory.allProfiles;
            vm.fileTypeDesc = '';
            vm.fileSizeDoc = '';

            if(vm.allProfiles != null) {
                vm.allProfiles = $linq(vm.allProfiles).orderBy("x => x." + Language.getActiveColumn(), HelperService.caseInsensitiveOrNullComparer).toArray();
            }
            
            vm.caculateFileProperties = function () {
                CommonService.getMimetypeDescription(vm.documentComponent.document.fileTypeDoc)
                    .then(function (data) {
                        if(vm.documentComponent.document.fileTypeDoc == 'application/pdf' && vm.documentComponent.document.docStatus == 'COMPLETE' && !vm.documentComponent.document.idPDFDocumentManager) {
                            data += ' ' +  $filter("translate")("global.documents.protectedPDF");
                        }
                        vm.fileTypeDesc = data;
                });
                vm.fileSizeDoc = CommonService.getFileSizeDescription(vm.documentComponent.document.sizeDoc) + ' (' + $filter("translate")("global.documents.originalDocument") + ')';
                if(vm.documentComponent.document.sizePDFDoc != null && vm.documentComponent.document.sizePDFDoc != ''){
                	var pdfSize = CommonService.getFileSizeDescription(vm.documentComponent.document.sizePDFDoc) + ' (' + $filter("translate")("global.documents.PDF") + ')';
                	vm.fileSizeDoc = vm.fileSizeDoc + ' - ' + pdfSize;
                }
            };

            vm.getTemplate = function() {
                if(vm.documentComponent.document.template && vm.documentComponent.document.template.name) {
                    return vm.documentComponent.document.template.name;
                } else if(vm.documentComponent.document.tramTemplate && vm.documentComponent.document.tramTemplate[vm.languageColumn]) {
                    return vm.documentComponent.document.tramTemplate[vm.languageColumn];
                } else if (vm.documentComponent.document.secTemplate && vm.documentComponent.document.secTemplate[vm.languageColumn]) {
                    return vm.documentComponent.document.secTemplate[vm.languageColumn];
                } else {
                    return '';
                }
            }

            vm.printProfiles = function(value) {
                var content = '';

                _.forEach(value, function (item) {
                    content += content != '' ? ',' + item[vm.languageColumn] : item[vm.languageColumn];
                });

                return content;
            }
            
            vm.updateDocumentProfiles = function(val, prop) {
            	vm.profilesBckup = vm.profiles;
            	var userProfiles = $linq($rootScope.LoggedUser.userProfiles).select("x => x.profile.id").toArray();
            	var docProfiles = $linq(val).select("x => x.id").toArray();
            	
            	var containsProfile = $linq(userProfiles).intersect(docProfiles).toArray();
            	
            	if(containsProfile.length == 0) {
            		DialogsFactory.confirm('global.documents.confirmUpdateProfileTitle', 'global.documents.confirmUpdateProfile')
                		.then(function() {
                			vm.documentComponent.updateDocument(val, prop, true);
                	}, function() {
                		vm.profiles = vm.profilesBckup;
                    });
            	} else {
            		vm.documentComponent.updateDocument(val, prop);
            	}
            }

            this.$onInit = function() {
                vm.caculateFileProperties();

                if(vm.documentComponent.document.profiles) {
                    _.forEach(vm.documentComponent.document.profiles, function(item) {
                        vm.profiles.push(item.profile);
                    });
                }
            }

            $rootScope.$on('docContentLoaded', function(event, args) {
                if(args){
                    vm.documentComponent.document.fileTypeDoc = args.fileTypeDoc;
                    vm.documentComponent.document.sizeDoc = args.sizeDoc;
                    vm.documentComponent.document.idDocumentManager = args.idDocumentManager;
                    vm.documentComponent.document.idPDFDocumentManager = args.idPDFDocumentManager;
                    vm.documentComponent.document.fileName = args.fileName;
                    vm.documentComponent.document.filePDFTypeDoc = args.filePDFTypeDoc;
                    vm.documentComponent.document.sizePDFDoc = args.sizePDFDoc;
                    vm.documentComponent.document.currentFileType = args.currentFileType;
                    vm.documentComponent.document.currentDocument = args.currentDocument;
                    vm.documentComponent.document.content = args.content;
                }
                vm.caculateFileProperties();
            });
        }]
    })