/**
 * Created by osirvent on 24/04/2017.
 */
angular
    .module('annexaApp')
    .component('annexaDocumentViewer', {
        templateUrl: function(){
            if(typeof window.navigator.msSaveBlob !== 'undefined'){
                return './components/doc/annexa-document-viewer/annexa-document-viewer-ie.html';
            }else{
                return './components/doc/annexa-document-viewer/annexa-document-viewer.html';
            }
        },
        controller: ['$rootScope','AnnexaDocumentActionsButtonFactory', 'DccumentsFactory', function ($rootScope, AnnexaDocumentActionsButtonFactory, DccumentsFactory) {
            var vm = this;
            
            vm.isIE = typeof window.navigator.msSaveBlob !== 'undefined';
            vm.show = false;
            vm.normalDoc = true;
            
            $rootScope.$on('docContentLoaded', function(event, args) {
                if(vm.document.content) {
                    vm.show = true;
                   	vm.normalDoc = true;
                }
            });
            vm.showAuthenticCopy = function(){
            	vm.document.content = undefined;
            	DccumentsFactory.getAuthCopyContent(vm.document.id).then(function(data) {
               		vm.fileType = 'application/pdf';
            		vm.document.content = data;
                    vm.normalDoc = false;
                }).catch(function (error) {
                });
            }
            vm.showDocument = function(){
            	vm.document.content = undefined;
            	DccumentsFactory.getDocContent(vm.document.id).then(function(data) {
            		if (AnnexaDocumentActionsButtonFactory.isDocumentConvertibleToPdf(vm.document, true) || vm.document.currentFileType == 'application/pdf') {
            			vm.fileType = 'application/pdf';
            		} else if(vm.document && vm.document.docStatus == 'NEW'){
                		vm.fileType = 'application/pdf';
                	} else {
            			vm.fileType = vm.document.currentFileType;
            		}
                    vm.document.content = data;
                    vm.normalDoc = true;
                }).catch(function (error) {
                });
            }
            this.$onInit = function () {
            	if (AnnexaDocumentActionsButtonFactory.isDocumentConvertibleToPdf(vm.document, true) || vm.document.currentFileType == 'application/pdf') {
            		vm.fileType = 'application/pdf';
            	} else if(vm.document && vm.document.docStatus == 'NEW'){
            		vm.fileType = 'application/pdf';
            	}else{
            		vm.fileType = vm.document.currentFileType;
            	}
            	
            }
        }],
        bindings: {
            document: '=',
            isEditing: '=',
            editDocumentFunction: '='
        }
    })