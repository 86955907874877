/**
 * Created by apop on 06/09/2018.
 */
angular
    .module('annexaApp')
    .component('annexaDocumentEni', {
        templateUrl: './components/doc/annexa-document-eni/annexa-document-eni.html',
        require: {
            documentComponent: '^^annexaDocument'
        },
        controller: ['Language', 'DccumentsFactory','$rootScope', 'GlobalDataFactory', '$filter','AnnexaDocumentActionsButtonFactory', function (Language, DccumentsFactory, $rootScope, GlobalDataFactory, $filter, AnnexaDocumentActionsButtonFactory) {
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();
            vm.versionNTI = $rootScope.app.configuration.default_eni_values.versionNTIdocument;
            vm.description  = "description";

            vm.printEstadoElaboracion = function (value) {
                if(value) {
                    if(typeof value === "object"){
                        value = value.id;
                    }
                    return value + " - " + $filter('translate')('global.ENIvalues.valorEstadoElaboracion.' + value);
                }
            };

            vm.eniValorEstadoElaboracion = [
                { id: "EE01", description: vm.printEstadoElaboracion("EE01") },
                { id: "EE02", description: vm.printEstadoElaboracion("EE02") },
                { id: "EE03", description: vm.printEstadoElaboracion("EE03") },
                { id: "EE04", description: vm.printEstadoElaboracion("EE04") },
                { id: "EE99", description: vm.printEstadoElaboracion("EE99") }
            ];

            vm.printTipoDocumental = function (value) {
                if(value) {
                    return value + " - " + $filter('translate')('global.ENIvalues.valorTipoDocumental.' + value);
                }
            };

            this.$onInit = function () {
                vm.isDocumentEniPresent = $rootScope.app.configuration.default_eni_values.show_document_eni;

                if(vm.isDocumentEniPresent && DccumentsFactory.documentEni) {
                    vm.documentoEni = DccumentsFactory.documentEni;
                    if (vm.documentoEni != null && vm.documentoEni.fechaCaptura != null) {
                        vm.eniFechaCaptura = $filter('date')(new Date(vm.documentoEni.fechaCaptura), 'dd/MM/yyyy HH:mm');
                    }
                    if (vm.documentoEni != null && vm.documentoEni.origenAdministracion) {
                        vm.eniOrigenCiudadanoAdministracion = $filter('translate')('global.ENIvalues.eniOrigenAdministracion');
                    } else if (vm.documentoEni != null && vm.documentoEni.origenCiudadano) {
                        vm.eniOrigenCiudadanoAdministracion = $filter('translate')('global.ENIvalues.eniOrigenCiudadano');
                    }
                }
            }
        }]
    })