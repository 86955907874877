/**
 * Created by osirvent on 24/04/2017.
 */
angular
    .module('annexaApp')
    .component('annexaDocument', {
        templateUrl: './components/doc/annexa-document/annexa-document.html',
        controller: ['$rootScope', 'DccumentsFactory', 'BoxFactory', 'globalModals', 'AnnexaFormlyFactory', 'Upload', '$state', '$scope', 'SignLocalFactory', '$filter', 'GlobalDataFactory', function($rootScope, DccumentsFactory, BoxFactory, globalModals, AnnexaFormlyFactory, Upload, $state, $scope, SignLocalFactory, $filter, GlobalDataFactory) {
            var vm = this;

            //region Actions

            vm.sendToSign = function() {
                vm.oldDocumentContent = vm.document.content;
                SignLocalFactory.sendToSignModal(undefined, [vm.document]);
            }

            vm.seeDocument = function(idDocumentManager) {
                if(!idDocumentManager) {
                    idDocumentManager = vm.document.currentDocument;
                }

                if(idDocumentManager) {
                    $rootScope.loading(true);
                    var windowReference = window.open();
                    DccumentsFactory.getDocContent(vm.document.id)
                        .then(function(data) {
                        	$rootScope.loading(false);
                        	windowReference.location = data;
                    }).catch(function (error) {
                        $rootScope.loading(false);
                        windowReference.close();
                    });
                }
            }

            vm.replaceDocumentModal = function() {
                var replaceDocument = function() {
                    if(this.annexaFormly.form.$valid) {
                        $rootScope.loading(true)
                        var self = this;
                        var data = this.annexaFormly.model.modal_body;
                        var customFields = vm.document.customFields;
                        var archiveClassification = vm.document.archiveClassification;

                        DccumentsFactory.updateDocumentFile(data.file, vm.document.id)
                            .then(function (data) {
                                vm.document = data;
                                vm.document.archiveClassification = !archiveClassification ? {} : archiveClassification;
                                vm.document.customFields = customFields;
                                vm.document.content = data.metadataSearch;
                                $rootScope.$broadcast('docContentLoaded');
                                $rootScope.loading(false);
                                self.close();
                            }).catch(function(error) {
                                $rootScope.loading(false);
                        });
                    }
                }

                var modal = angular.copy(globalModals.uploadFile);

                if(vm.document.docStatus == 'NEW'){
                    modal.title = 'global.literals.attachDocument';
                }else{
                    modal.title = 'global.documents.new.replaceDocument';
                }

                modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = {};
                modal.annexaFormly.options = {};
                modal.annexaFormly.options.formState = {readOnly: false};
                AnnexaFormlyFactory.showModal('modalAddAttachment', modal, replaceDocument, false);
            }

            vm.completeDocument = function(){
                $rootScope.loading(true);
                DccumentsFactory.definitiveDocument({id: vm.document.id})
                    .then(function (data) {
                        $state.transitionTo('annexa.doc.documents.all');
                        $rootScope.loading(false);
                    }).catch(function (error) {
                    $rootScope.loading(false);
                });
            };

            //endregion

            //region General

            vm.dossierModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.tram'").toArray();

            vm.document = DccumentsFactory.document;
            if(vm.document && !vm.document.allRelatedDocuments){
            	vm.document.allRelatedDocuments = [];
            }
            vm.document.content = '';
            vm.canEdit = DccumentsFactory.canEdit(vm.document);
            vm.canEditTitle = DccumentsFactory.canEditTitle(vm.canEdit);
            vm.isEditing = undefined;
            vm.alerts = [];
            vm.editingDocument = false;
            vm.esetMode = $rootScope.esetMode;
            vm.fileTypeDesc = '';
            vm.isAuthenticCopy = DccumentsFactory.isAuthenticCopy;

            //endregion

            //region Boxes
            var addDocumentSave = function(newRelatedDocument) {
                $rootScope.loading(true);
                
                DccumentsFactory.newRelatedDocument(vm.document.id, newRelatedDocument)
                    .then(function(data) {
                    	vm.documentBox.content.length =0;
                    	_.forEach(data.allRelatedDocuments, function(doc){
                    		 vm.documentBox.content.push(doc);
                    	});
                    	$rootScope.$broadcast('annexaBoxDocumentsUpdateCounter', { content: vm.documentBox.content, relationType: 'DOCUMENT'});
                        $rootScope.loading(false);
                    }).catch(function (error) {
                        $rootScope.loading(false);
                        console.error(error);
                        if (error.data && error.data.message) {
                        	vm.alerts.push({ msg: error.data.message });
                        } else {
                        	vm.alerts.push({ msg: 'global.errors.errorCreateDocument' });
                        }
                });
            }

            $scope.$on('annexaBoxDocumentsUpdateContentENI', function(event, args){ 
            	if(args.controller && args.controller == 'DocumentController' && args.content && vm.documentBox && vm.documentBox.isEdit ) {
            		vm.documentBox.content.length =0;
                	_.forEach(args.content, function(doc){
                		 vm.documentBox.content.push(doc);
                	});
                	$rootScope.$broadcast('annexaBoxDocumentsUpdateCounter', { content: vm.documentBox.content, relationType: 'INTERNAL_DOCUMENT'});
            	}
            });
            
            $scope.$on('annexaBoxDocumentsDelete', function (event, args) {
                if(args.origin && args.origin == 'annexa-document-box' && vm.documentBox.isEdit) {
                	 DccumentsFactory.deleteRelatedDocument(vm.document.id, args.removedId)
                        .then(function () {
                            //Empty
                        }).catch(function(error) {
                            console.error(error);
                    });
                }
            });
            
            vm.documentBox = {
            		boxTitle: 'global.books.view.relatedDocuments',
                    permissions: {new: 'new_document', view: 'view_documents'},
                    search: {
                        placeholder: 'global.literals.placeholser_search_documents',
                        advanced: true,
                        saveSearch: addDocumentSave,
                        addAfter: true,
                        additionalFilter: {documentBoxNormalSearch: true}
                    },
                    content: vm.document.allRelatedDocuments,
                    new: { multiple: { scanner: true } },
                    config: {},
                    origin: 'annexa-document-box',
                    isEdit:true
                }
            
            vm.registerEntriesBox = {
                boxTitle: 'global.literals.relatedRegisterEntries',
                permissions: {},
                search: undefined,
                new: undefined,
                content:  DccumentsFactory.documentRegisterEntries ?
                    $linq(DccumentsFactory.documentRegisterEntries).select(function(x) {
                        return {
                            id: x.id,
                            entryType: x.entryType,
                            entryNumber: x.entryNumber,
                            originEntryNumber: x.originEntryNumber,
                            extract: x.extract,
                            entryDiligences: x.entryDiligences,
                            registerDate: x.registerDate,
                            thirds: x.thirds,
                            registerEntryOffice: x.registerEntryOffice,
                            csv: x.csv
                        }
                    }).toArray() : [],
                config: { },
                origin: 'document'
            }

            $scope.$on('documentActionButtonExecuted', function (event, args) {
                if(args.button == 'createRegisterEntry'){
                    vm.registerEntriesBox.content.push(args.data);
                } else if(_.contains(['complete', 'returnToDraft'], args.button)) {
                    $state.reload();
                }
            });

            vm.dossiersBox = angular.copy(BoxFactory.DossierBox).initialize('global.literals.relatedDossiers');
            vm.dossiersBox.boxDefinition.content = [];
            if(DccumentsFactory.documentDossiers) {
                vm.dossiersBox.boxDefinition.content = DccumentsFactory.documentDossiers;
            }

            var addTaskSave = function(newTask) {
                if(newTask){
                    if(vm.document.archiveClassification && vm.document.archiveClassification.model) {
                        vm.document.archiveClassification = vm.checkTreeValue(vm.document.archiveClassification.model, undefined);
                    }                                                           

                    _.forEach(vm.document.tasks, function (item, index) {
                        if(!item.document) {
                            vm.document.tasks[index].document = { id: vm.document.id };
                        }
                        if(!item.documents) {
                        	vm.document.tasks[index].documents = [];
                        	vm.document.tasks[index].documents.push(vm.document);
                        }
                    });

                    DccumentsFactory.updateDocument(vm.document)
                    	.then(function(data) {
                    		vm.document.profiles = data.profiles;
                    	}).catch(function (error) {
                        vm.tasksBox.boxDefinition.content.pop();
                    	});                                        
                }
            }
            
            vm.taskBox = {
            	// s'afegeixen aquestos permissos per poder veure els botons i la cerca
                permissions: { new: 'new_document', view: 'view_documents' },
                search: { placeholder: 'global.literals.placeholderSearchTaskBox', advanced: true, saveSearch: addTaskSave },
                content: vm.document.tasks,
                new: {},
                isEdit: true,
                config: {},
                origin: 'annexa-document'
            }

            $scope.$on('annexaBoxTasksDelete', function (event, args) {
                if(args.origin && args.origin == 'annexa-document') {
                	if(vm.document.archiveClassification && vm.document.archiveClassification.model) {
                        vm.document.archiveClassification = vm.checkTreeValue(vm.document.archiveClassification.model, undefined);
                    }
                    
                    DccumentsFactory.updateDocument(vm.document)
                	.then(function(data) {
                		vm.document.profiles = data.profiles;
                	}).catch(function (error) {
                		vm.tasksBox.boxDefinition.content.pop();
                	});                                        
                 }
            });
            
            //endregion

            //region Mètodes

            vm.checkTreeValue = function(value, empty) {
                if(value && value.$selected && value.$selected.id) {
                    return { id: value.$selected.id };
                } else {
                    return empty;
                }
            }

            vm.updateDocument = function (val, prop, reload) {


                var checkSelect = function(value) {
                    if(value && value.id) {
                        return { id: value.id };
                    } else {
                        return {};
                    }
                }

                var doc = angular.copy(vm.document);

                if(doc.archiveClassification) {
                    doc.archiveClassification = vm.checkTreeValue(doc.archiveClassification.model, undefined);
                }

                var fixedValue = val;

                if(prop == 'archiveClassification') {
                    fixedValue = vm.checkTreeValue(val, {});
                } else if(prop == 'profiles') {
                    fixedValue = [];
                    _.forEach(val, function (item) {
                        fixedValue.push({ profile: { id: item.id } });
                    });
                } else if(prop == 'name'){
                    if(!doc || !doc.type || !doc.type.id || !DccumentsFactory.validDocumentName(fixedValue, doc.type.id)){
                        return $filter("translate")("global.errors.docNameFormat");
                    }
                }

                doc[prop] = fixedValue;

                $rootScope.loading(true);
                DccumentsFactory.updateDocument(doc)
                    .then(function(data) {
                    	vm.document.profiles = data.profiles;
                    
                    	if(reload) {
                    		$state.reload();
                    	}
                    	
                        $rootScope.loading(false);
                    }).catch(function (error) {
                        $rootScope.loading(false);
                });
            };

            $scope.$on('documentActionButtonExecuting', function (event, args) {
                switch (args.button) {
                    case 'editOnline':
                        vm.editDocument(true);
                        break;
                }
            });

            $scope.$on('SendToSignModalFinished', function(event, args) {
                if(args.data && Array.isArray(args.data) && args.data.length > 0) {
                    vm.document = args.data[0];
                    vm.document.content = vm.oldDocumentContent;
                    $rootScope.$broadcast('docContentLoaded');
                    $state.reload();
                }
            });

            vm.editDocument = function (value) {
                if(!value) {
                    var oldContent = vm.document.content;

                    vm.document.content = '';

                    DccumentsFactory.getDocContent(vm.document.id)
                        .then(function(data) {
                            vm.document.content = data;
                            $rootScope.$broadcast('docContentLoaded');
                        }).catch(function (error) {
                            vm.document.content = oldContent;
                    });
                }

                vm.isEditing = value;
            }

            //endregion

            vm.editDocument(false);

            var fromState = $rootScope.previousState;
            var fromParams = $rootScope.previousStateParams;

            if(fromState && fromState.name && fromParams && fromParams.type) {
                angular.element('[ui-sref="annexa.doc.documents.type({type: ' + fromParams.type + '})"]').parent().addClass('active');
            } else {
                angular.element('[ui-sref="annexa.doc.documents.all"]').parent().addClass('active');
            }
        }]
    })