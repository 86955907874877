angular
    .module('annexaApp')
    .component('annexaBoxDocuments',{
        templateUrl: './components/doc/annexa-box-documents/annexa-box-documents.html',
        controller:['DccumentsFactory', '$rootScope', 'AnnexaObjectBoxFactory', '$scope', 'Language', '$filter', 'AnnexaFormlyFactory', 'GlobalDataFactory', function(DccumentsFactory, $rootScope, AnnexaObjectBoxFactory, $scope, Language, $filter, AnnexaFormlyFactory, GlobalDataFactory) {
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();
            
            $scope.$on('annexaAdvancedSearchModalAdd', function (event, args) {
                if(args.id == 'modalASDocuments') {
                    var id = undefined;
                    
                    if(args.selected && args.selected.length > 0){
                    	vm.search.searchedValue = { id: id, object: args.selected };
                        vm.search.completeAdd();
                    }

                    args.modal.close();
                }
            });

            this.$onInit = function() {
                vm.docModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.doc.documents.all'").toArray();

                if(!vm.boxTitle) {
                    vm.boxTitle = 'global.literals.documents';
                }

                if(!vm.search) {
                    vm.search = {};
                }

                vm.search.addedSelect = 'x => x.document.id';
                vm.search.origin = 'doc';
                vm.search.type = 'Document';
                vm.search.processSearch = function (object) {
                    return object.name;
                }

                if(!vm.search.completeAdd) {
                    vm.search.completeAdd = function() {
                    	var newContent = [];
                    	if(vm.search.searchedValue && vm.search.searchedValue.object && Array.isArray(vm.search.searchedValue.object)){
                    		_.forEach(vm.search.searchedValue.object, function(obj){
                    			newContent.push({document: JSOG.decode(obj), relationType: 'DOCUMENT'});
                    		});
                    	}else{
                    		newContent.push({document: vm.search.searchedValue.object, relationType: 'DOCUMENT'} );
                    	}

                		if(!vm.search.saveSearch || (vm.search.saveSearch && !vm.search.addAfter)) {
                			_.forEach(newContent, function(obj){
                				vm.content.push(obj);
                        	});
                        }
                        
                		vm.search.searchedValue = { id: -1, value: '' };
                        if(vm.search.saveSearch ) {
                            vm.search.saveSearch(newContent);
                        }
                        
                        $rootScope.$broadcast('annexaBoxDocumentsUpdateCounter', { content: vm.content, relationType: 'DOCUMENT'});
                    }
                }

                vm.search.advancedSearch = function() {
                	 var docStatusRender = function(data, type, full, meta) {
                         var content = '';

                         if(DccumentsFactory.statuses && DccumentsFactory.statuses.length > 0) {
                             var status = $linq(DccumentsFactory.statuses).singleOrDefault(undefined, "x => x.id == '" + data + "'");

                             if(status) {
                                 content += '<div class="text-center">';
                                 content += '    <span class="fa-stack" title="' + $filter('translate')('global.documentState.' + status.name) + '">';
                                 content += '        <i class="fa fa-' + status.icon + ' text-' + status.style + ' text-lg fa-stack-1x"></i>';
                                 content += '    </span>';
                                 content += '</div>';
                             }
                         }

                         return content;
                     }
                	
                    var showStyledDocumentTypeAcronym = $rootScope.app.configuration.show_styled_document_type_acronym.value;
                    
                    var includeRegister = null;
                    if(vm.config && vm.config.module && vm.config.module == 'REGSALIDA'){
                    	includeRegister = true;
                    }
                    
                    var advancedSearchModal = {
                        title: 'global.literals.advanced_search_documents',
                        size: 'modal-lg',
                        filter: [
                            { id: 'code', type: 'text', order: 0, label: 'global.literals.code' },
                            { id: 'name', type: 'text', order: 1, label: 'global.literals.title' },
                            { id: 'csv', type: 'text', order: 2, label: 'global.documents.new.csv' },
                            { id: 'template', type: 'select', order: 0, label: 'global.literals.template', dataType: 'LOCAL', data: $linq(GlobalDataFactory.templates).orderBy("x => x." + vm.languageColumn).toArray(), addAll: true, nameProperty: 'description', userChoosen: true },
                            { id: 'section', type: 'select', order: 1, label: 'global.literals.section', dataType: 'LOCAL', data: $linq(GlobalDataFactory.sections).orderBy("x => x." + vm.languageColumn).toArray(), addAll: true, nameProperty: vm.languageColumn, userChoosen: true },
                            { id: 'type', type: 'select', order: 2, label: 'global.literals.type', dataType: 'LOCAL', data: $linq(GlobalDataFactory.documentTypes).where("x => x.isPublic").toArray(), addAll: true, nameProperty: vm.languageColumn, userChoosen: true },
                            { id: 'origin', type: 'select', order: 3, label: 'global.literals.module', dataType: 'LOCAL', data: GlobalDataFactory.docModules, addAll: true, nameProperty: vm.languageColumn, callAux: true, userChoosen: true },
                            { id: 'createUser', type: 'text', order: 4, label: 'global.literals.user', callAux: true, userChoosen: true },
                            { id: 'meta', type: 'text', order: 5, label: 'global.literals.meta', callAux: true, userChoosen: true },
                            { id: 'modifyDdate', type: 'dateRange', order: 6, label: 'global.literals.modified', callAux: true, userChoosen: true },
                            { id: 'dossierNumber', type: 'text', order: 7, label: 'global.literals.numExp', callAux: true, userChoosen: true },
                            { id: 'docStatus', type: 'select', order: 8, label: 'global.literals.status', callAux: true, dataType: 'LOCAL', data: $linq(GlobalDataFactory.documentStatuses).orderBy("x => x.orderStatus").toArray(), addAll: true, nameProperty: 'nameLiteral', userChoosen: true }
                        ],
                        tableDefinition: {
                            id: 'tableAdvancedSearchDocuments',
                            origin: 'doc',
                            objectType: 'Document',
                            sortName: 'name',
                            sort: [[3, 'asc']],
                            columns: [
                            	{ id: 'docStatus', width: '10%', title: $filter('translate')('global.literals.status'), render: docStatusRender, sortable: false },
                                { id: 'type', width: '10%', column: showStyledDocumentTypeAcronym ? new IconColumn($filter, 'global.literals.type', 'acronym', vm.languageColumn) : new ObjectTextColumn($filter, 'global.literals.type', 'acronym') , sortable: false },
                                { id: 'id', width: '10%', column: new UIRouterStateColumn($filter, 'global.literals.code', 'annexa.doc.documents.view({code: \'[data]\'})', undefined, 'var code = full.id; if(full.code) { code = full.code.substr((full.code.indexOf(full.id) - 2), full.id.toString().length + 2); } code;') },
                                { id: 'id', column: new UIRouterStateColumnNoLink($filter, 'global.literals.title', 'annexa.doc.documents.view({code: \'[data]\'})', 'name')  },
                                { id: 'createUser', width: '20%', column: new UserColumn($filter, 'global.literals.user') },
                                { id: 'modifyDate', width: '20%', column: new DateTimeColumn($filter, 'global.literals.modifyDate') }
                            ], 
                            noLoading: true,
                            callAuxToAdd: { added: $linq(vm.content).select("x => x.document.id").toArray(), includeRegisterEntry: includeRegister }
                        },
                        multiple: true
                    }

                    AnnexaFormlyFactory.showAnnexaAdvancedSearchModal('modalASDocuments', advancedSearchModal);
                }
                
                if(!vm.new) {
                    vm.new = {};
                }

                vm.new.newFunc = function() {
                    var preCloseModal = function(document) {
                        vm.search.searchedValue = {
                            id: document.id, value: document.name,
                            object: {
                                id: document.id,
                                docStatus: document.docStatus,
                                name: document.name,
                                profiles: document.profiles,
                                code: document.code,
                                type: document.type
                            }
                        };
                        vm.search.completeAdd();
                    }

                    DccumentsFactory.openNewDocument(undefined, preCloseModal, vm.config.module, vm.config.documentTitle)
                }

                if(vm.new.multiple) {
                    var self = this;

                    var preCloseModalFunction = function() {
                        _.forEach(this.wizard.steps[this.wizard.steps.length - 1].annexaFormly.fields[0].data.documents, function(item) {
                            if(item.procState == 'complete') {
                                vm.search.searchedValue = {
                                    id: item.docData.id, value: item.docData.name,
                                    object: {
                                        id: item.docData.id,
                                        docStatus: item.docData.docStatus,
                                        name: item.docData.name,
                                        profiles: item.docData.profiles,
                                        code: item.docData.code,
                                        type: item.docData.type
                                    }
                                };
                                vm.search.completeAdd();
                            }
                        });
                    }

                    vm.new.newMultipleFunc = function() {
                        DccumentsFactory.openMasiveDocumentModalFolder(preCloseModalFunction, self);
                    }

                    if(vm.new.multiple.scanner && $rootScope.app.configuration.load_documents_from_scan_folder.value) {
                        vm.new.newMultipleScannerFunc = function () {
                            DccumentsFactory.openMasiveDocumentModalScan(preCloseModalFunction, self);
                        }
                    }
                }
                vm.new.showNewInteroperable = false;
                if($rootScope.app.configuration.add_document_via_oberta != null){
                	vm.new.showNewInteroperable = $rootScope.app.configuration.add_document_via_oberta.value;
                }
                vm.new.newInteroperable = function(){
                    var self = this;
                    var commitModal = function(documents) {
                    	_.forEach(documents, function (doc) {
                            vm.search.searchedValue = {
                                id: doc.id, value: doc.name,
                                object: {
                                    id: doc.id,
                                    docStatus: doc.docStatus,
                                    name: doc.name,
                                    profiles: doc.profiles,
                                    code: doc.code,
                                    type: doc.type
                                }
                            };
                            vm.search.completeAdd();
                        });
                    }
                    var thirds = undefined;
                    if(vm.registerEntry){
                    	thirds = vm.registerEntry.thirds;
                    } else if(vm.config.thirdBox && vm.config.thirdBox.content) {
                    	thirds = vm.config.thirdBox.content;
                    }
                    DccumentsFactory.openNewInteroperbleModal(self, thirds, commitModal, vm.config.module, undefined);
                }
            }
        }],
        bindings: {
            boxTitle: '@?',
            permissions: '=',
            search: '=',
            new: '=',
            content: '=',
            emptyText: '@',
            isEdit: '=',
            entryType: '@?',
            config: '=',
            origin: '@',
            registerEntry: '='
        }
    })
    .component('annexaBoxDocumentsRender', {
        templateUrl: './components/doc/annexa-box-documents/annexa-box-documents-render.html',
        require: {
            objectBoxComponent: '^^annexaObjectBox'
        },
        controller:['$state', 'DialogsFactory', '$rootScope', function($state, DialogsFactory, $rootScope) {
            var vm = this;
            
            $rootScope.$on('annexaBoxDocumentsUpdateCounter', function (event, args) {
            	if (args.content && args.relationType) {
            		vm.filteredContent = $linq(args.content).where("x => x.relationType == '" + args.relationType + "'").toArray();
            		if (args.relationType == 'DOCUMENT') {
            			vm.labelRegisterEntryDocs = 'success';
            			vm.labelRegisterEntryInternalDocs = '';
            			vm.counterRegisterEntryDocs = vm.filteredContent.length;
            		} else {
            			vm.labelRegisterEntryDocs = '';
            			vm.labelRegisterEntryInternalDocs = 'success';
            			vm.counterRegisterEntryInternalDocs = vm.filteredContent.length;
            		}
            	} else if (args.content) {
            		vm.counterRegisterEntryDocs = $linq(args.content).where("x => x.relationType == 'DOCUMENT'").count();
            		vm.counterRegisterEntryInternalDocs = $linq(args.content).where("x => x.relationType == 'INTERNAL_DOCUMENT'").count();
            	}
            });
            
            vm.counterRegisterEntryDocs = 0;
            vm.counterRegisterEntryInternalDocs = 0;
            vm.labelRegisterEntryDocs = '';
            vm.labelRegisterEntryInternalDocs = '';
            vm.filteredContent = [];
            if (vm.content) {
            	vm.filteredContent = $linq(vm.content).where("x => x.relationType == 'DOCUMENT'").toArray();
            	vm.labelRegisterEntryDocs = 'success';
            	$rootScope.$broadcast('annexaBoxDocumentsUpdateCounter', { content: vm.content });
            }

            vm.filterRelationType = function (relationType) {
            	vm.filteredContent = $linq(vm.content).where("x => x.relationType == '" + relationType + "'").toArray();
            	if (relationType && relationType == 'DOCUMENT') {
            		vm.labelRegisterEntryDocs = 'success';
            		vm.labelRegisterEntryInternalDocs = '';
            	} else {
            		vm.labelRegisterEntryDocs = '';
            		vm.labelRegisterEntryInternalDocs = 'success';
            	}
            }
            
            vm.getDocStatusTitle = function (document) {
                return 'global.documentState.' + document.docStatus;
            }

            vm.getDocStatusIcon = function (document) {
                switch (document.docStatus) {
                    case 'NEW':
                        return 'fa fa-circle-o text-blue fa-lg';
                        break;
                    case 'NEW_AND_ATTACHMENT':
                        return 'fa fa-adjust text-blue fa-lg';
                        break;
                    case 'PENDING_SIGN':
                        return 'fa fa-circle text-blue fa-lg';
                        break;
                    case 'SIGNED':
                        return 'fa fa-circle text-success fa-lg';
                        break;
                    case 'REJECTED':
                        return 'fa fa-circle text-danger fa-lg';
                        break;
                    case 'COMPLETE':
                        return 'fa fa-dot-circle-o text-success fa-lg v-t';
                        break;

                }
            }

            vm.getDocumentPermissionsOrigin = function () {
                return $state.current.name == 'annexa.reg.input.diligences.edit' ? 'DILIGENCE' : '';
            }

            vm.delete = function(index) {
                DialogsFactory.confirm('global.literals.delete', 'global.literals.deleteDocumentBox')
                    .then(function (data) {
                        var removed = angular.copy(vm.filteredContent[index]);
                        vm.filteredContent.splice(index, 1);
                        $rootScope.$broadcast('annexaBoxDocumentsUpdateCounter', { content: vm.filteredContent, relationType: removed.relationType});

                        var indexRemove = $linq(vm.content).indexOf("x => x.id == " + removed.id);
                        if(indexRemove != -1) {
                            vm.content.splice(indexRemove, 1);
                        }

                        if(vm.isEdit) {
                            $rootScope.$broadcast('annexaBoxDocumentsDelete', { removedId: removed.id, origin: vm.origin});
                        }
                    }).catch(function (data) {
                    //Empty
                });
            }
        }],
        bindings: {
            content: '=',
            emptyText: '@',
            isEdit: '=',
            entryType: '@?',
            origin: '@'
        }
    })